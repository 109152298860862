.review {
    &__name {
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 1.3rem;
    }
    &__text {
        line-height: 2;

        @media screen and #{$media_sm} {
            line-height: 1.8;
        }
    }
}
