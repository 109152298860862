@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
html {
  font-size: 14px;
}

body {
  font-family: "Roboto", sans-serif;
  color: #000;
  letter-spacing: .01em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin-bottom: 16px;
}

b, strong {
  font-weight: 500;
}

label {
  font-weight: bold;
  color: #ef7f1a;
}

ul {
  padding-left: 20px;
}

a {
  text-decoration: none;
  transition: color .2s ease;
}

a:hover {
  text-decoration: underline;
  color: #4c6575;
}

a:focus {
  text-decoration: none;
  outline: none;
  color: #4c6575;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #000;
}

h1,
.h1 {
  margin-bottom: 80px;
  font-size: 5rem;
  font-weight: 500;
}

@media screen and (max-width: 991px) {
  h1,
  .h1 {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) {
  h1,
  .h1 {
    margin-bottom: 60px;
    font-size: 3.4rem;
  }
}

@media screen and (max-width: 575px) {
  h1,
  .h1 {
    margin-bottom: 40px;
    font-size: 3rem;
  }
}

@media screen and (max-width: 420px) {
  h1,
  .h1 {
    font-size: 2.4rem;
  }
}

h2,
.h2 {
  margin-bottom: 40px;
  font-size: 3rem;
}

@media screen and (max-width: 767px) {
  h2,
  .h2 {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 575px) {
  h2,
  .h2 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 420px) {
  h2,
  .h2 {
    font-size: 2rem;
  }
}

.lead {
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .lead {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 575px) {
  .lead {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 420px) {
  .lead {
    font-size: 1.4rem;
  }
}

html {
  height: 100%;
}

body {
  position: relative;
  height: 100%;
  padding: 0;
  margin: 0;
  background: #fff;
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: #fafafa;
}

body::-webkit-scrollbar-thumb {
  background-color: #ef7f1a;
}

body.fancybox-active {
  margin-right: 10px !important;
}

.img {
  max-width: 100%;
}

.icon {
  display: inline-block;
  text-decoration: none;
  outline: none;
  background-size: cover;
  background-repeat: no-repeat;
}

.icon.icon-feature-1 {
  width: 56px;
  height: 56px;
  background-image: url("../images/icon-feature-1.svg");
}

.icon.icon-feature-2 {
  width: 50px;
  height: 56px;
  background-image: url("../images/icon-feature-2.svg");
}

.icon.icon-feature-3 {
  width: 52px;
  height: 56px;
  background-image: url("../images/icon-feature-3.svg");
}

.icon.icon-feature-4 {
  width: 55px;
  height: 56px;
  background-image: url("../images/icon-feature-4.svg");
}

.icon.icon-feature-5 {
  width: 48px;
  height: 56px;
  background-image: url("../images/icon-feature-5.svg");
}

.icon.icon-feature-6 {
  width: 43px;
  height: 56px;
  background-image: url("../images/icon-feature-6.svg");
}

.main {
  position: relative;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
}

.main__content {
  position: relative;
  z-index: 1;
}

.bg-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: 50% 50%;
  background-size: cover;
  z-index: -1;
}

.bg-image > * {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: 50% 50%;
  background-size: cover;
}

.bg-image.bg-pattern {
  background-size: auto;
}

.bg-image.bg-pattern > * {
  background-size: auto;
}

.bg-image-parallax img,
.bg-image img {
  width: 100%;
  height: 100%;
  font-family: "object-fit: cover";
  object-fit: cover;
}

.header {
  padding: 22px 0;
}

.header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 991px) {
  .header .container {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .header .container {
    justify-content: center;
  }
}

.header__logo {
  display: inline-flex;
  align-items: center;
  color: #ef7f1a;
}

@media screen and (max-width: 767px) {
  .header__logo {
    flex-direction: column;
  }
}

.header__logo:hover, .header__logo:focus {
  color: #ef7f1a;
  text-decoration: none;
}

.header__logo span {
  display: inline-block;
  margin-left: 30px;
  font-size: 2.4rem;
  font-weight: 700;
}

@media screen and (max-width: 991px) {
  .header__logo span {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .header__logo span {
    display: block;
    margin-top: 10px;
    margin-left: 0;
    font-size: 1.6rem;
    text-align: center;
  }
}

.header__phone {
  display: inline-flex;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .header__phone {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) {
  .header__phone {
    display: inline-flex;
    width: auto;
    margin-top: 30px;
  }
}

@media screen and (max-width: 575px) {
  .header__phone {
    flex-direction: column;
  }
}

.header__phone span {
  display: block;
  margin-right: 30px;
  font-size: 1.4rem;
  font-weight: 700;
  color: #000;
  white-space: nowrap;
  text-align: right;
}

@media screen and (max-width: 575px) {
  .header__phone span {
    margin-right: 0;
    font-size: 1.2rem;
  }
  .header__phone span:last-child {
    margin-bottom: 10px;
  }
}

.footer {
  display: block;
  background-color: #fff;
}

.footer-info {
  padding: 70px;
}

@media screen and (max-width: 575px) {
  .footer-info {
    padding: 40px;
  }
}

@media screen and (max-width: 420px) {
  .footer-info {
    padding: 40px 20px;
  }
}

.footer-info__logo {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  color: #000;
}

.footer-info__logo img {
  width: 95px;
}

.footer-info__logo span {
  display: inline-block;
  margin-left: 20px;
  font-size: 1.14rem;
  font-weight: 700;
}

.footer-info__logo:hover, .footer-info__logo:focus {
  color: #ef7f1a;
  text-decoration: none;
}

.footer-info__list {
  padding: 0;
  margin: 0;
}

.footer-info__list > li {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  font-size: 1.14rem;
  list-style: none;
}

.footer-info__list > li span {
  font-size: 1.8rem;
  color: #ef7f1a;
  margin-right: 20px;
}

.footer-info__list > li + li {
  margin-top: 10px;
}

.footer-info__list > li a {
  color: #000;
}

.footer .map {
  min-height: 460px;
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.vertical-gap {
  margin-top: -30px;
}

.vertical-gap > [class*="col"] {
  padding-top: 30px;
}

.row.gap-20 {
  margin-right: -10px;
  margin-left: -10px;
}

.row.gap-20 > [class*="col"] {
  padding-right: 10px;
  padding-left: 10px;
}

.row.gap-20.vertical-gap {
  margin-top: -20px;
}

.row.gap-20.vertical-gap > [class*="col"] {
  padding-top: 20px;
}

.row.gap-10 {
  margin-right: -5px;
  margin-left: -5px;
}

.row.gap-10 > [class*="col"] {
  padding-right: 5px;
  padding-left: 5px;
}

.row.gap-10.vertical-gap {
  margin-top: -10px;
}

.row.gap-10.vertical-gap > [class*="col"] {
  padding-top: 10px;
}

/*
 * Display Helper
 */
.db {
  display: block;
}

.dib {
  display: inline-block;
}

/*
 * Text Color Helper
 */
.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

.text-main-1 {
  color: #ef7f1a !important;
}

.text-main-2 {
  color: #f57e57 !important;
}

.text-main-3 {
  color: #4c6575 !important;
}

.text-grey-1 {
  color: #909090 !important;
}

.text-grey-2 {
  color: #5a5a5a !important;
}

.text-grey-3 {
  color: #e5e5e5 !important;
}

/*
 * Margin Helper
 */
.m-0 {
  margin: 0px !important;
}

.m-1 {
  margin: 1px !important;
}

.m-2 {
  margin: 2px !important;
}

.m-3 {
  margin: 3px !important;
}

.m-4 {
  margin: 4px !important;
}

.m-5 {
  margin: 5px !important;
}

.m-6 {
  margin: 6px !important;
}

.m-7 {
  margin: 7px !important;
}

.m-8 {
  margin: 8px !important;
}

.m-9 {
  margin: 9px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-50 {
  margin: 50px !important;
}

.m-60 {
  margin: 60px !important;
}

.m-70 {
  margin: 70px !important;
}

.m-80 {
  margin: 80px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-1 {
  margin-top: 1px !important;
}

.mt-2 {
  margin-top: 2px !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-6 {
  margin-top: 6px !important;
}

.mt-7 {
  margin-top: 7px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-9 {
  margin-top: 9px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-1 {
  margin-left: 1px !important;
}

.ml-2 {
  margin-left: 2px !important;
}

.ml-3 {
  margin-left: 3px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-6 {
  margin-left: 6px !important;
}

.ml-7 {
  margin-left: 7px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-9 {
  margin-left: 9px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-1 {
  margin-right: 1px !important;
}

.mr-2 {
  margin-right: 2px !important;
}

.mr-3 {
  margin-right: 3px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-6 {
  margin-right: 6px !important;
}

.mr-7 {
  margin-right: 7px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-9 {
  margin-right: 9px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-1 {
  margin-bottom: 1px !important;
}

.mb-2 {
  margin-bottom: 2px !important;
}

.mb-3 {
  margin-bottom: 3px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-6 {
  margin-bottom: 6px !important;
}

.mb-7 {
  margin-bottom: 7px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-9 {
  margin-bottom: 9px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.navbar {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 0;
  font-family: "Roboto", sans-serif;
  background-color: #000;
  z-index: 10;
}

.navbar .navbar-close {
  display: none;
}

@media screen and (max-width: 991px) {
  .navbar {
    padding: 20px 0;
  }
  .navbar .navbar-close {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    cursor: pointer;
    background-color: rgba(76, 101, 117, 0.8);
    opacity: 0;
    visibility: hidden;
    transition: visibility .2s ease, opacity .2s ease;
    z-index: 9;
  }
  .navbar .navbar-close.active {
    opacity: 1;
    visibility: visible;
  }
}

.navbar > [class*="container"] {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  z-index: 10;
}

.navbar .navbar-burger {
  position: absolute;
  display: none;
  top: 50%;
  right: 40px;
  width: 24px;
  height: 16px;
  margin-top: -8px;
  cursor: pointer;
}

.navbar .navbar-burger > span {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #fff;
}

@media screen and (max-width: 991px) {
  .navbar .navbar-burger {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (max-width: 575px) {
  .navbar .navbar-burger {
    right: 20px;
  }
}

.navbar .navbar-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 991px) {
  .navbar .navbar-content {
    position: absolute;
    flex-direction: column;
    justify-content: center;
    top: 0;
    right: 0;
    width: auto;
    height: 100vh;
    padding: 30px;
    background-color: #fff;
    box-shadow: -1px 0 0 0 rgba(0, 0, 0, 0.04);
    visibility: hidden;
    opacity: 0;
    transition: transform .4s ease, visibility .2s ease, opacity .2s ease;
    transform: translateX(100%);
    z-index: 11;
  }
  .navbar .navbar-content.active {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
  }
}

.navbar .navbar-content > li {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: .74rem;
  list-style: none;
}

.navbar .navbar-content > li a {
  display: block;
  padding: 6px 10px;
  font-size: 1rem;
  font-weight: 300;
  color: #fff;
  text-transform: uppercase;
  transition: color .2s ease;
}

@media screen and (max-width: 1199px) {
  .navbar .navbar-content > li a {
    padding: 12px 6px;
  }
}

@media screen and (max-width: 991px) {
  .navbar .navbar-content > li a {
    padding: 10px 6px;
    font-size: 1rem;
    color: #000;
  }
}

.navbar .navbar-content > li a:hover, .navbar .navbar-content > li a:focus {
  color: #ef7f1a;
  text-decoration: none;
}

.navbar .navbar-content .drop-item {
  position: relative;
}

.navbar .navbar-content .drop-item::after {
  content: "";
  position: absolute;
  display: block;
  top: 100%;
  left: 50%;
  height: 13px;
  margin-top: -7px;
  margin-left: -10px;
  border: 9px solid transparent;
  border-bottom: 9px solid #fff;
  opacity: 0;
  transition: opacity .2s ease;
}

.navbar .navbar-content .drop-item::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 16px;
  margin-bottom: -10px;
}

.navbar .navbar-content .drop-item:hover::after {
  opacity: 1;
}

.navbar .navbar-content .drop-item:hover > a {
  color: #ef7f1a;
}

.navbar .navbar-content .drop-item:hover > .dropdown {
  visibility: visible;
  opacity: 1;
}

.navbar .navbar-content .dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  width: 1170px;
  margin-top: 10px;
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  transition: visibility .2s ease, opacity .2s ease;
}

@media screen and (max-width: 991px) {
  .navbar .navbar-content .dropdown {
    width: 100%;
    margin-top: 0;
  }
  .navbar .navbar-content .dropdown .container {
    padding: 0;
  }
}

.navbar .navbar-content .dropdown::after {
  content: "";
  position: absolute;
  top: 0;
  right: -1000px;
  bottom: 0;
  left: -1000px;
  background-color: #fff;
  z-index: -1;
}

@media screen and (max-width: 991px) {
  .navbar .navbar-content .dropdown::after {
    display: none;
  }
}

.navbar .navbar-content .dropdown ul {
  display: flex;
  justify-content: space-between;
  padding: 6px 30px;
  margin: 0;
}

@media screen and (max-width: 991px) {
  .navbar .navbar-content .dropdown ul {
    flex-direction: column;
    padding: 10px 0;
  }
}

.navbar .navbar-content .dropdown ul > li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.navbar .navbar-content .dropdown a {
  padding: 6px;
  font-weight: 400;
  color: #909090;
  white-space: nowrap;
  transition: color .2s ease;
}

@media screen and (max-width: 991px) {
  .navbar .navbar-content .dropdown a {
    white-space: normal;
  }
}

.navbar .navbar-content .dropdown a:hover {
  color: #000;
}

.navbar .navbar-content .dropdown a:focus {
  color: #909090;
}

.service-item {
  position: relative;
  display: block;
  padding: 20px 30px 20px 20px;
  overflow: hidden;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.03);
  transition: box-shadow .3s ease;
}

@media screen and (max-width: 575px) {
  .service-item {
    padding: 20px;
  }
}

.service-item:hover {
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
}

.service-item__decoration {
  position: absolute;
  top: 0;
  right: 0;
  padding: 14px 16px;
  text-align: right;
}

.service-item__decoration span {
  position: relative;
  color: #fff;
  z-index: 1;
}

.service-item__decoration::after {
  content: "";
  position: absolute;
  top: -75px;
  right: -35px;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  background-color: #ef7f1a;
}

@media screen and (max-width: 575px) {
  .service-item__decoration {
    position: static;
    margin: -20px -20px 20px;
    border-radius: 10px 10px 0 0;
    background-color: #ef7f1a;
  }
  .service-item__decoration::after {
    display: none;
  }
}

.service-item__title {
  max-width: 76%;
  margin-bottom: 20px;
  font-size: 1.4rem;
  font-weight: 700;
  color: #ef7f1a;
}

.service-item__text strong {
  font-weight: 900;
}

.service-item__lead {
  font-size: 2rem;
}

.job-item__img img {
  width: 100%;
}

.job-item__title {
  min-height: 50px;
  margin-top: 25px;
  margin-bottom: 18px;
}

@media screen and (max-width: 575px) {
  .job-item__title {
    min-height: auto;
  }
}

.job-item__text > div:first-child {
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: max-height .4s ease, visibility .2s ease, opacity .2s ease;
}

.job-item__text span {
  display: block;
  font-weight: 700;
  color: #ef7f1a;
}

.job-item__text.collapse-active > div {
  max-height: 300px;
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 420px) {
  .job-item__text.collapse-active > div {
    max-height: 400px;
  }
}

.job-item__more {
  color: #000;
  text-decoration: underline;
}

.job-item__more:hover {
  color: #ef7f1a;
  text-decoration: underline;
}

.review__name {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 1.3rem;
}

.review__text {
  line-height: 2;
}

@media screen and (max-width: 575px) {
  .review__text {
    line-height: 1.8;
  }
}

.btn {
  border: 0;
  border-radius: 0;
  color: #4c6575;
  text-decoration: none;
}

.btn:hover, .btn:focus {
  color: #4c6575;
  text-decoration: none;
  box-shadow: none;
}

.btn-main {
  display: inline-block;
  padding: 12px 50px;
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  border-radius: 40px;
  background-color: #ef7f1a;
  transition: background-color .2s ease;
}

.btn-main:hover, .btn-main:focus {
  color: #fff;
  background-color: #000;
}

.btn-inverse {
  background-color: #000;
}

.btn-inverse:hover, .btn-inverse:focus {
  background-color: #ef7f1a;
}

.btn-sm {
  padding: 10px 20px;
}

.feedback-block__text {
  margin-bottom: 70px;
}

@media screen and (max-width: 767px) {
  .feedback-block__text {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 575px) {
  .feedback-block__text {
    margin-bottom: 30px;
  }
}

.feedback-block__privacy {
  margin-top: 70px;
}

@media screen and (max-width: 767px) {
  .feedback-block__privacy {
    margin-top: 50px;
  }
}

@media screen and (max-width: 575px) {
  .feedback-block__privacy {
    margin-top: 30px;
  }
}

.feedback-block__privacy a {
  color: inherit;
  text-decoration: underline;
}

.feedback-block__privacy a:hover, .feedback-block__privacy a:focus {
  color: #ef7f1a;
}

button {
  cursor: pointer;
}

/* Placeholders */
::placeholder,
.form-control::placeholder {
  color: inherit;
}

/* Remove outline from focused buttons */
button:focus {
  outline: 0;
}

/* From */
.form {
  /* fix for select on iOs devices */
}

.form .form-group {
  position: relative;
  margin-bottom: 0;
}

@media screen and (min-width: 576px) {
  .form .form-group:nth-child(1) > * {
    border-radius: 30px 0 0 30px;
  }
  .form .form-group:nth-child(2) > * {
    border-radius: 0 30px 30px 0;
  }
}

.form__control {
  position: relative;
  height: 45px;
  padding: 10px 0 10px 30px;
  border: 0;
  border-radius: 30px;
  box-shadow: none;
  color: rgba(90, 90, 90, 0.6);
  transition: border-color .3s ease, color .2s ease;
  transition-delay: .1s;
}

.form__control::placeholder {
  color: rgba(90, 90, 90, 0.6);
  opacity: 1;
  transition: color .2s ease;
}

.form__control:hover, .form__control:focus {
  color: #5a5a5a;
  box-shadow: none;
}

.form__control:hover::placeholder, .form__control:focus::placeholder {
  color: #5a5a5a;
}

.form textarea,
.form textarea.form-control,
.form textarea.form-control.form__control {
  min-height: 45px;
  height: 45px;
  max-height: 160px;
}

.form textarea.form__control_2,
.form textarea.form-control.form__control_2,
.form textarea.form-control.form__control.form__control_2 {
  min-height: 48px;
  height: 164px;
  max-height: 190px;
}

.form label {
  font-family: "Roboto", sans-serif;
  font-size: .9rem;
}

.form select.form-control:not([size]):not([multiple]) {
  height: 34px;
}

.form select.form-control {
  color: #000;
  cursor: pointer;
}

.form select.form-control:focus {
  cursor: pointer;
}

.form select.form-control option {
  color: #000;
}

@-moz-document url-prefix() {
  .form select.form-control option {
    color: inherit;
  }
}

.form select.form-control[multiple] option {
  color: inherit;
}

.form select.form-control {
  background-color: #fff;
}

.form-control-descrtiption {
  font-size: 0.8rem;
  margin-top: 7px;
  color: #9a9a9a;
}

.form-control-descrtiption a {
  text-decoration: none;
}

.form-check {
  display: block;
  margin: 6px 0;
  padding-left: 0;
}

.form__check {
  cursor: pointer;
  position: relative;
  display: block;
  margin-bottom: 0;
  padding: 3px 0 4px 40px;
  color: #4c6575;
  font-size: .6rem;
  font-weight: 400;
  letter-spacing: 0;
  vertical-align: middle;
  user-select: none;
}

.form__check-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.form__check-input ~ .form__check-checkmark {
  background-color: #fff;
  border: 1px solid #5a5a5a;
  transition: background-color .15s ease, border-color .15s ease;
}

.form__check-input:checked ~ .form__check-checkmark {
  background-color: #fff;
  border-color: #5a5a5a;
}

.form__check-input:checked ~ .form__check-checkmark::after, .form__check-input:checked ~ .form__check-checkmark::before {
  display: block;
}

.form__check-checkmark {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  border-radius: 0;
  background-color: #fff;
}

.form__check-checkmark::after, .form__check-checkmark::before {
  content: '';
  position: absolute;
  display: none;
  transform: rotate(-45deg);
  transform-origin: 0% 100%;
}

.form__check-checkmark::after {
  top: 10px;
  left: 11px;
  width: 3px;
  height: 7px;
  border-top: solid #4c6575;
  border-width: 7px;
  animation: check-one 0.6s linear;
}

@keyframes check-one {
  0% {
    border-width: 0px;
  }
  20% {
    border-width: 7px;
  }
  100% {
    border-width: 7px;
  }
}

.form__check-checkmark::before {
  top: 15px;
  left: 12px;
  width: 13px;
  height: 3px;
  border-left: solid #4c6575;
  border-width: 13px;
  animation: check-two 0.6s linear;
}

@keyframes check-two {
  0% {
    border-width: 0px;
  }
  20% {
    border-width: 0px;
  }
  40% {
    border-width: 13px;
  }
  100% {
    border-width: 13px;
  }
}

.form .form__file {
  display: inline-block;
  position: relative;
  width: 84px;
  height: 72px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.form .form__file:last-of-type {
  margin-right: 0;
}

.form .form__file--input {
  visibility: hidden;
  width: 0;
  height: 0;
}

.form .form__file--label {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  background-image: url(../../assets/images/add-img-default.png);
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  transition: box-shadow .2s ease;
}

.form .form__file--label:hover, .form .form__file--label:focus {
  box-shadow: inset 0 0 0 1px #ef7f1a;
}

.form .form__file--label::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.form .form__file--img {
  width: 100%;
  height: 100%;
}

.form .form__file--remove {
  position: absolute;
  z-index: 1;
  width: 14px;
  height: 14px;
  top: 5px;
  right: 5px;
  border-radius: 7px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
  transition: background-color .2s ease, color .2s ease;
}

.form .form__file--remove > .icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 1px;
  top: 1px;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
  font-size: 1rem;
}

.form .form__file--remove:hover, .form .form__file--remove:focus {
  background-color: #f57e57;
  color: #fff;
  text-decoration: none;
}

/* Form Style Feedback */
.form__feedback .form__control {
  border: 1px solid #5a5a5a;
  border-radius: 2px;
  font-size: 0.75rem;
  transition: border-color .2s ease, box-shadow .2s ease;
}

.form__feedback .form__control:hover, .form__feedback .form__control:focus {
  border-color: #ef7f1a;
}

.form__feedback .form__control:focus {
  box-shadow: 0 0 1px 1px rgba(239, 127, 26, 0.2);
}

.form__feedback .form__control.active {
  border-color: #f57e57;
}

.form__feedback .form__control.active:focus {
  box-shadow: 0 0 1px 1px rgba(245, 126, 87, 0.2);
}

.form__signin .form__control {
  height: 40px;
  padding: 10px 14px;
  border: 1px solid #5a5a5a;
  border-radius: 2px;
  font-size: 0.8rem;
  transition: border-color .2s ease, box-shadow .2s ease;
}

.form__signin .form__control:hover, .form__signin .form__control:focus {
  border-color: #ef7f1a;
}

.form__signin .form__control:focus {
  box-shadow: 0 0 1px 1px rgba(239, 127, 26, 0.2);
}

.form__signin .form__control.active {
  border-color: #f57e57;
}

.form__signin .form__control.active:focus {
  box-shadow: 0 0 1px 1px rgba(245, 126, 87, 0.2);
}

.form__signin--btn {
  width: 280px;
  padding: 6px 20px;
  font-size: 1.2rem;
}

.form__signin--link {
  text-decoration: none;
  font-size: 0.9rem;
}

.form__signin--link:hover {
  text-decoration: underline;
}

.form__signin .form-check .form__check {
  padding-left: 24px;
  font-size: 0.73rem;
  font-weight: 400;
  color: #000;
}

.form__signin .form-check .form__check-checkmark {
  top: 2px;
}

.form__ad .form__control {
  height: 36px;
  padding: 8px 14px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  font-size: 0.8rem;
  transition: border-color .2s ease, box-shadow .2s ease;
}

.form__ad .form__control:hover, .form__ad .form__control:focus {
  border-color: #ef7f1a;
}

.form__ad .form__control:focus {
  box-shadow: 0 0 1px 1px rgba(239, 127, 26, 0.2);
}

.form__ad .form__control.active {
  border-color: #f57e57;
}

.form__ad .form__control.active:focus {
  box-shadow: 0 0 1px 1px rgba(245, 126, 87, 0.2);
}

.form__ad--btn {
  width: 280px;
  padding: 6px 20px;
  font-size: 1.2rem;
}

.form__ad--link {
  text-decoration: none;
  font-size: 0.9rem;
}

.form__ad--link:hover {
  text-decoration: underline;
}

.form__ad .form-check .form__check {
  padding-left: 24px;
  font-size: 0.73rem;
  font-weight: 400;
  color: #000;
}

.form__ad .form-check .form__check-checkmark {
  top: 2px;
}

.feature {
  display: inline-flex;
  padding-right: 30px;
}

.feature-icon {
  margin-right: 20px;
}

.feature-title {
  display: block;
  margin-top: -4px;
  margin-bottom: 20px;
  font-size: 1.4rem;
  font-weight: 700;
  color: #000;
}

.feature-text {
  display: block;
  color: rgba(0, 0, 0, 0.8);
}

.numbers-title {
  margin-bottom: 0;
  color: #fff;
}

.numbers-text {
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.8);
}

.box,
.box-1,
.box-2,
.box-3 {
  position: relative;
  display: block;
  z-index: 1;
}

.box-1 {
  padding: 80px 0;
}

.box-2 {
  padding: 120px 0;
}

.box-3 {
  padding: 200px 0;
}

@media screen and (max-width: 991px) {
  .box-3 {
    padding: 180px 0;
  }
}

@media screen and (max-width: 767px) {
  .box-3 {
    padding: 160px 0;
  }
}

@media screen and (max-width: 575px) {
  .box-3 {
    padding: 140px 0;
  }
}

@media screen and (max-width: 420px) {
  .box-3 {
    padding: 120px 0;
  }
}

.slider {
  padding-bottom: 50px;
}

@media screen and (max-width: 575px) {
  .slider {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 420px) {
  .slider {
    padding-bottom: 20px;
  }
}

.slider .swiper-slide {
  height: auto;
}

.slider .swiper-pagination {
  bottom: 0;
}

.slider .swiper-pagination .swiper-pagination-bullet {
  width: 5px;
  height: 5px;
  margin: 0 6px;
  opacity: 1;
}

.slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #ef7f1a;
}

.slider .swiper-button-next,
.slider .swiper-button-prev {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  margin-top: -35px;
  font-size: 2rem;
  color: #000;
  background: none;
  background-color: #ededed;
  transition: background-color .2s ease, color .2s ease;
}

.slider .swiper-button-next:hover,
.slider .swiper-button-prev:hover {
  color: #fff;
  background-color: #ef7f1a;
}

.slider .swiper-button-next:focus,
.slider .swiper-button-prev:focus {
  box-shadow: none;
  outline: none;
}

.slider .swiper-button-next {
  right: 0;
  left: auto;
}

.slider .swiper-button-prev {
  right: auto;
  left: 0;
}

.slider-main .swiper-slide.width-sm {
  width: 377px;
  max-width: 100%;
}

.slider-main .swiper-slide.width-lg {
  width: 752px;
  max-width: 100%;
}

.slider-main .swiper-wrapper {
  align-items: stretch;
}

.slider-main .swiper-wrapper .swiper-slide a {
  display: inline-block;
  width: auto;
  height: 100%;
}

.slider-reviews .swiper-slide {
  padding: 0 120px;
}

@media screen and (max-width: 991px) {
  .slider-reviews .swiper-slide {
    padding: 0 100px;
  }
}

@media screen and (max-width: 767px) {
  .slider-reviews .swiper-slide {
    padding: 0 80px;
  }
}

@media screen and (max-width: 575px) {
  .slider-reviews .swiper-slide {
    padding: 0 40px;
  }
}

@media screen and (max-width: 420px) {
  .slider-reviews .swiper-slide {
    padding: 0 20px;
  }
}

.slider-reviews .swiper-pagination .swiper-pagination-bullet {
  background: #fff;
}

.slider-reviews .swiper-button-next,
.slider-reviews .swiper-button-prev {
  color: #fff;
  background: none;
}

@media screen and (max-width: 575px) {
  .slider-reviews .swiper-button-next,
  .slider-reviews .swiper-button-prev {
    display: none;
  }
}
