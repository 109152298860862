html {
    height: 100%;
}

body {
    position: relative;
    height: 100%;
    padding: 0;
    margin: 0;
    background: $bg_body;

    &::-webkit-scrollbar{
    	width: $scrollbar-width;
    	background-color: $scrollbar-color-fon;
    }
    &::-webkit-scrollbar-thumb{
    	background-color: $scrollbar-color;
    }
    &.fancybox-active {
        margin-right: $scrollbar-width !important;
    }
}

.img {
    max-width: 100%;
}

// Fix IE Icons
.icon {
    display: inline-block;
    text-decoration: none;
    outline: none;
    background-size: cover;
    background-repeat: no-repeat;

    &.icon-feature-1 {
        width: 56px;
        height: 56px;
        background-image: url("../images/icon-feature-1.svg");
    }
    &.icon-feature-2 {
        width: 50px;
        height: 56px;
        background-image: url("../images/icon-feature-2.svg");
    }
    &.icon-feature-3 {
        width: 52px;
        height: 56px;
        background-image: url("../images/icon-feature-3.svg");
    }
    &.icon-feature-4 {
        width: 55px;
        height: 56px;
        background-image: url("../images/icon-feature-4.svg");
    }
    &.icon-feature-5 {
        width: 48px;
        height: 56px;
        background-image: url("../images/icon-feature-5.svg");
    }
    &.icon-feature-6 {
        width: 43px;
        height: 56px;
        background-image: url("../images/icon-feature-6.svg");
    }
}

// Main Content
.main {
    position: relative;
    width: 100%;
    min-height: 100%;
    overflow: hidden;

    &__content {
        position: relative;
        z-index: 1;
    }
}

// bg image
.bg-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: 50% 50%;
    background-size: cover;
    z-index: -1;

    > * {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-position: 50% 50%;
        background-size: cover;
    }
    &.bg-pattern {
        background-size: auto;

        > * {
            background-size: auto;
        }
    }
}

// Object Fit
.bg-image-parallax,
.bg-image {
    img {
        width: 100%;
        height: 100%;
        // stylelint-disable-next-line
        font-family: "object-fit: cover";
        object-fit: cover;
    }
}
