.box,
.box-1,
.box-2,
.box-3 {
    position: relative;
    display: block;
    z-index: 1;
}

.box-1 {
    padding: 80px 0;
}
.box-2 {
    padding: 120px 0;
}
.box-3 {
    padding: 200px 0;

    @media screen and #{$media_lg} {
        padding: 180px 0;
    }
    @media screen and #{$media_md} {
        padding: 160px 0;
    }
    @media screen and #{$media_sm} {
        padding: 140px 0;
    }
    @media screen and #{$media_xs} {
        padding: 120px 0;
    }
}
