.navbar {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 0;
    font-family: $font_navbar;
    background-color: #000;
    z-index: 10;

    .navbar-close {
        display: none;
    }

    @media screen and #{$media_lg} {
        padding: 20px 0;

        .navbar-close {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            cursor: pointer;
            background-color: rgba($color_main_3, .8);
            opacity: 0;
            visibility: hidden;
            transition: visibility .2s ease, opacity .2s ease;
            z-index: 9;

            &.active {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    > [class*="container"] {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        z-index: 10;
    }

    .navbar-burger {
        position: absolute;
        display: none;
        top: 50%;
        right: 40px;
        width: 24px;
        height: 16px;
        margin-top: -8px;
        cursor: pointer;

        > span {
            display: block;
            width: 100%;
            height: 2px;
            background-color: #fff;
        }

        @media screen and #{$media_lg} {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }
        @media screen and #{$media_sm} {
            right: 20px;
        }
    }

    .navbar-content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        padding: 0;
        margin: 0;

        @media screen and #{$media_lg} {
            position: absolute;
            flex-direction: column;
            justify-content: center;
            top: 0;
            right: 0;
            width: auto;
            height: 100vh;
            padding: 30px;
            background-color: #fff;
            box-shadow: -1px 0 0 0 rgba(0, 0, 0, .04);
            visibility: hidden;
            opacity: 0;
            transition: transform .4s ease, visibility .2s ease, opacity .2s ease;
            transform: translateX(100%);
            z-index: 11;

            &.active {
                visibility: visible;
                opacity: 1;
                transform: translateX(0);
            }
        }

        > li {
            display: inline-block;
            padding: 0;
            margin: 0;
            font-size: .74rem;
            list-style: none;

            a {
                display: block;
                padding: 6px 10px;
                font-size: 1rem;
                font-weight: 300;
                color: #fff;
                text-transform: uppercase;
                transition: color .2s ease;

                @media screen and #{$media_xl} {
                    padding: 12px 6px;
                }
                @media screen and #{$media_lg} {
                    padding: 10px 6px;
                    font-size: 1rem;
                    color: #000;
                }

                &:hover,
                &:focus {
                    color: $color_main_1;
                    text-decoration: none;
                }
            }
        }

        .drop-item {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                display: block;
                top: 100%;
                left: 50%;
                height: 13px;
                margin-top: -7px;
                margin-left: -10px;
                border: 9px solid transparent;
                border-bottom: 9px solid #fff;
                opacity: 0;
                transition: opacity .2s ease;
            }
            &::before {
                content: "";
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                height: 16px;
                margin-bottom: -10px;
            }

            &:hover {
                &::after {
                    opacity: 1;
                }

                > a {
                    color: $color_main_1;
                }

                > .dropdown {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        .dropdown {
            position: absolute;
            top: 100%;
            right: 0;
            left: 0;
            width: 1170px;
            margin-top: 10px;
            background-color: #fff;
            visibility: hidden;
            opacity: 0;
            transition: visibility .2s ease, opacity .2s ease;

            @media screen and #{$media_lg} {
                width: 100%;
                margin-top: 0;

                .container {
                    padding: 0;
                }
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: -1000px;
                bottom: 0;
                left: -1000px;
                background-color: #fff;
                z-index: -1;

                @media screen and #{$media_lg} {
                    display: none;
                }
            }

            ul {
                display: flex;
                justify-content: space-between;
                padding: 6px 30px;
                margin: 0;

                @media screen and #{$media_lg} {
                    flex-direction: column;
                    padding: 10px 0;
                }

                > li {
                    padding: 0;
                    margin: 0;
                    list-style: none;
                }
            }


            a {
                padding: 6px;
                font-weight: 400;
                color: $color_grey_1;
                white-space: nowrap;
                transition: color .2s ease;

                @media screen and #{$media_lg} {
                    white-space: normal;
                }

                &:hover {
                    color: #000;
                }
                &:focus {
                    color: $color_grey_1;
                }
            }
        }
    }
}
