html {
    font-size: 14px;
}

body {
    font-family: $font_body;
    color: $color_body;
    letter-spacing: .01em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    margin-bottom: 16px;
}

b, strong {
    font-weight: 500;
}

label {
    font-weight: bold;
    color: $color_main_1;
}

ul {
    padding-left: 20px;
}

a {
    text-decoration: none;
    transition: color .2s ease;

    &:hover {
        text-decoration: underline;
        color: $color_main_3;
    }
    &:focus {
        text-decoration: none;
        outline: none;
        color: $color_main_3;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 20px;
    font-family: $font_headings;
    font-weight: 700;
    color: #000;
}
h1,
.h1 {
    margin-bottom: 80px;
    font-size: 5rem;
    font-weight: 500;

    @media screen and #{$media_lg} {
        font-size: 4rem;
    }
    @media screen and #{$media_md} {
        margin-bottom: 60px;
        font-size: 3.4rem;
    }
    @media screen and #{$media_sm} {
        margin-bottom: 40px;
        font-size: 3rem;
    }
    @media screen and #{$media_xs} {
        font-size: 2.4rem;
    }
}
h2,
.h2 {
    margin-bottom: 40px;
    font-size: 3rem;

    @media screen and #{$media_md} {
        font-size: 2.8rem;
    }
    @media screen and #{$media_sm} {
        font-size: 2.4rem;
    }
    @media screen and #{$media_xs} {
        font-size: 2rem;
    }
}

.lead {
    font-size: 2rem;

    @media screen and #{$media_md} {
        font-size: 1.8rem;
    }
    @media screen and #{$media_sm} {
        font-size: 1.6rem;
    }
    @media screen and #{$media_xs} {
        font-size: 1.4rem;
    }
}
