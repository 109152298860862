.numbers {

    &-title {
        margin-bottom: 0;
        color: #fff;
    }
    &-text {
        font-size: 1.5rem;
        color: rgba(255, 255, 255, .8);
    }
}
