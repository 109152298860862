.feature {
    display: inline-flex;
    padding-right: 30px;

    &-icon {
        margin-right: 20px;
    }

    &-title {
        display: block;
        margin-top: -4px;
        margin-bottom: 20px;
        font-size: 1.4rem;
        font-weight: 700;
        color: #000;
    }
    &-text {
        display: block;
        color: rgba(#000, .8);
    }
}
