.container {
    @media screen and #{$media_xxl} {
        max-width: 1200px;
    }
}

.vertical-gap {
    margin-top: -30px;

    > [class*="col"] {
        padding-top: 30px;
    }
}

.row.gap-20 {
    margin-right: -10px;
    margin-left: -10px;

    > [class*="col"] {
        padding-right: 10px;
        padding-left: 10px;
    }

    &.vertical-gap {
        margin-top: -20px;

        > [class*="col"] {
            padding-top: 20px;
        }
    }
}

.row.gap-10 {
    margin-right: -5px;
    margin-left: -5px;

    > [class*="col"] {
        padding-right: 5px;
        padding-left: 5px;
    }

    &.vertical-gap {
        margin-top: -10px;

        > [class*="col"] {
            padding-top: 10px;
        }
    }
}
