@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

// Main Color
$color_main_1: #ef7f1a !default;
$color_main_2: #f57e57 !default;
$color_main_3: #4c6575 !default;
$color_main_4: #fff !default;
$color_body: #000 !default;
$bg_body: #fff !default;

// Color Text
$color_text_1: #2c527f !default;

// Color Gradient
$color_gradient_1: linear-gradient(to bottom, #0f173b, #1f2d6a) !default;
$color_gradient_2: linear-gradient(to top, #0f173b, #1f2d6a) !default;
$color_gradient_3: linear-gradient(to bottom, #3656fe, #09a5fa) !default;
$color_gradient_4: linear-gradient(to top, #3656fe, #09a5fa) !default;

// Color Grey
$color_grey_1: #909090 !default;
$color_grey_2: #5a5a5a !default;
$color_grey_3: #e5e5e5 !default;
$color_grey_4: #9a9a9a !default;

// Color Status
$color_active: #27ae60;
$color_archive: #9a9a9a;
$color_delete: #eb5757;

// Background-color
$color_bg_1: #c2e7ff !default;
$color_bg_2: #ecf7ff !default;

// Spinner
$spinner-color: rgba(#fff, 0.3);
$spinner-color_inner: #fff;
$spinner-size: 90px;
$spinner-size_inner: $spinner-size * 0.35;
$spinner-weight: 4px;
$spinner-speed: .6s;

// Scrollbar
$scrollbar-width: 10px;
$scrollbar-color: $color_main_1;
$scrollbar-color-fon: #fafafa;

// Colors
$colors: (
    "white": #fff,
    "black": #000,
    "main-1": $color_main_1,
    "main-2": $color_main_2,
    "main-3": $color_main_3,
    "grey-1": $color_grey_1,
    "grey-2": $color_grey_2,
    "grey-3": $color_grey_3
) !default;

// Colors Social
$colors_social: (
    "facebook": #475995,
    "googleplus": #c14d3d,
    "instagram": #d12054,
    "odnoklassniki": #ee8208,
    "paypal": #1d3283,
    "skype": #62aded,
    "tumblr": #384961,
    "twitter": #6da9de,
    "vimeo": #69b5e7,
    "vk": #657da0,
    "youtube": #c6271e
) !default;

// Fonts
$font_body: 'Roboto', sans-serif;
$font_headings: 'Roboto', sans-serif;
$font_navbar: 'Roboto', sans-serif;

// media
$media_xxl: "(min-width: 1200px)" !default;
$media_xl: "(max-width: 1199px)" !default;
$media_lg: "(max-width: 991px)" !default;
$media_md: "(max-width: 767px)" !default;
$media_sm: "(max-width: 575px)" !default;
$media_xs: "(max-width: 420px)" !default;
$media_xxs: "(max-width: 380px)" !default;
