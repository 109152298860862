.job-item {
    &__img img {
        width: 100%;
    }
    &__title {
        min-height: 50px;
        margin-top: 25px;
        margin-bottom: 18px;

        @media screen and #{$media_sm} {
            min-height: auto;
        }
    }
    &__text {
        > div:first-child {
            max-height: 0;
            overflow: hidden;
            visibility: hidden;
            opacity: 0;
            transition: max-height .4s ease, visibility .2s ease, opacity .2s ease;
        }

        span {
            display: block;
            font-weight: 700;
            color: $color-main-1;
        }

        &.collapse-active {
            > div {
                max-height: 300px;
                visibility: visible;
                opacity: 1;

                @media screen and #{$media_xs} {
                    max-height: 400px;
                }
            }
        }
    }
    &__more {
        color: #000;
        text-decoration: underline;

        &:hover {
            color: $color-main-1;
            text-decoration: underline;
        }
    }
}
