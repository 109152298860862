.service {
    &-item {
        position: relative;
        display: block;
        padding: 20px 30px 20px 20px;
        overflow: hidden;
        border-radius: 10px;
        background-color: #fff;
        box-shadow: 0 0 14px 0 rgba(0, 0, 0, .03);
        transition: box-shadow .3s ease;

        @media screen and #{$media_sm} {
            padding: 20px;
        }

        &:hover {
            box-shadow: 0 0 14px 0 rgba(0, 0, 0, .1);
        }
        &__decoration {
            position: absolute;
            top: 0;
            right: 0;
            padding: 14px 16px;
            text-align: right;

            span {
                position: relative;
                color: #fff;
                z-index: 1;
            }

            &::after {
                content: "";
                position: absolute;
                top: -75px;
                right: -35px;
                width: 150px;
                height: 150px;
                border-radius: 100%;
                background-color: $color_main_1;
            }

            @media screen and #{$media_sm} {
                position: static;
                margin: -20px -20px 20px;
                border-radius: 10px 10px 0 0;
                background-color: $color-main-1;

                &::after {
                    display: none;
                }
            }
        }
        &__title {
            max-width: 76%;
            margin-bottom: 20px;
            font-size: 1.4rem;
            font-weight: 700;
            color: $color_main_1;
        }
        &__text {
            strong {
                font-weight: 900;
            }
        }
        &__lead {
            font-size: 2rem;
        }
    }
}
