.header {
    padding: 22px 0;

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and #{$media_lg} {
            flex-wrap: wrap;
        }
        @media screen and #{$media_md} {
            justify-content: center;
        }
    }
    &__logo {
        display: inline-flex;
        align-items: center;
        color: $color_main_1;

        @media screen and #{$media_md} {
            flex-direction: column;
        }

        &:hover,
        &:focus {
            color: $color_main_1;
            text-decoration: none;
        }

        span {
            display: inline-block;
            margin-left: 30px;
            font-size: 2.4rem;
            font-weight: 700;

            @media screen and #{$media_lg} {
                font-size: 2rem;
            }
            @media screen and #{$media_md} {
                display: block;
                margin-top: 10px;
                margin-left: 0;
                font-size: 1.6rem;
                text-align: center;
            }
        }
    }
    &__phone {
        display: inline-flex;
        align-items: center;

        @media screen and #{$media_lg} {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            margin-top: 20px;
        }
        @media screen and #{$media_md} {
            display: inline-flex;
            width: auto;
            margin-top: 30px;
        }
        @media screen and #{$media_sm} {
            flex-direction: column;
        }

        span {
            display: block;
            margin-right: 30px;
            font-size: 1.4rem;
            font-weight: 700;
            color: #000;
            white-space: nowrap;
            text-align: right;

            @media screen and #{$media_sm} {
                margin-right: 0;
                font-size: 1.2rem;

                &:last-child {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
