.slider {
    padding-bottom: 50px;

    @media screen and #{$media_sm} {
        padding-bottom: 40px;
    }
    @media screen and #{$media_xs} {
        padding-bottom: 20px;
    }

    .swiper-slide {
        height: auto;
    }

    .swiper-pagination {
        bottom: 0;

        .swiper-pagination-bullet {
            width: 5px;
            height: 5px;
            margin: 0 6px;
            opacity: 1;

            &.swiper-pagination-bullet-active {
                background-color: $color-main-1;
            }
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 70px;
        margin-top: -35px;
        font-size: 2rem;
        color: #000;
        background: none;
        background-color: #ededed;
        transition: background-color .2s ease, color .2s ease;

        &:hover {
            color: #fff;
            background-color: $color-main-1;
        }
        &:focus {
            box-shadow: none;
            outline: none;
        }
    }
    .swiper-button-next {
        right: 0;
        left: auto;
    }
    .swiper-button-prev {
        right: auto;
        left: 0;
    }
}

.slider-main {
    .swiper-slide {
        &.width-sm {
            width: 377px;
            max-width: 100%;
        }
        &.width-lg {
            width: 752px;
            max-width: 100%;
        }
    }
    .swiper-wrapper {
        align-items: stretch;

        .swiper-slide {
            a {
                display: inline-block;
                width: auto;
                height: 100%;
            }
        }
    }
}

.slider-reviews {
    .swiper-slide {
        padding: 0 120px;

        @media screen and #{$media_lg} {
            padding: 0 100px;
        }
        @media screen and #{$media_md} {
            padding: 0 80px;
        }
        @media screen and #{$media_sm} {
            padding: 0 40px;
        }
        @media screen and #{$media_xs} {
            padding: 0 20px;
        }
    }

    .swiper-pagination {
        .swiper-pagination-bullet {
            background: #fff;
        }
    }
    .swiper-button-next,
    .swiper-button-prev {
        color: #fff;
        background: none;

        @media screen and #{$media_sm} {
            display: none;
        }
    }
}
