.footer {
    display: block;
    background-color: #fff;

    &-info {
        padding: 70px;

        @media screen and #{$media_sm} {
            padding: 40px;
        }
        @media screen and #{$media_xs} {
            padding: 40px 20px;
        }

        &__logo {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            color: #000;

            img {
                width: 95px;
            }

            span {
                display: inline-block;
                margin-left: 20px;
                font-size: 1.14rem;
                font-weight: 700;
            }
            &:hover,
            &:focus {
                color: $color-main-1;
                text-decoration: none;
            }
        }

        &__list {
            padding: 0;
            margin: 0;

            > li {
                display: flex;
                align-items: center;
                padding: 0;
                margin: 0;
                font-size: 1.14rem;
                list-style: none;

                span {
                    font-size: 1.8rem;
                    color: $color-main-1;
                    margin-right: 20px;
                }

                + li {
                    margin-top: 10px;
                }

                a {
                    color: #000;
                }
            }
        }
    }

    .map {
        min-height: 460px;
    }
}
