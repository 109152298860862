// Btn main
.btn {
    border: 0;
    border-radius: 0;
    color: $color_main_3;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $color_main_3;
        text-decoration: none;
        box-shadow: none;
    }
}

.btn-main {
    display: inline-block;
    padding: 12px 50px;
    font-size: 1rem;
    font-weight: 400;
    color: #fff;
    border-radius: 40px;
    background-color: $color_main_1;
    transition: background-color .2s ease;

    &:hover,
    &:focus {
        color: #fff;
        background-color: #000;
    }
}

.btn-inverse {
    background-color: #000;

    &:hover,
    &:focus {
        background-color: $color_main_1;
    }
}

.btn-sm {
    padding: 10px 20px;
}
